/* ----- Homepage ------- */
    header.home-header{
        padding: 140px 0px 70px;
        text-align: center;
        border-bottom: 1px solid #cccccc;
    }
    header.home-header .title{
        font-family: paytone-one;
        font-size: 28px;
        margin-bottom: 24px;
    }
    header.home-header p{
        font-family: dm-sans-regular;
        font-size: 18px;
    }