/* ----- Main ------- */
    /* Defined Color Theme */
    :root {
        --theme-color-primary: #371b58;
        --theme-color-primary-hover: #2e1648;
        --theme-color-accent: #4c3575;
        --theme-color-accent-hover: #3f2b60;
        --theme-color-secondary: #7858a6;
        --theme-color-secondary-hover: #6a4e92;
        --theme-color-third: #dfd4e3;
    }

    /* Defined Font Style */
    @font-face {
        font-family: 'paytone-one';
        src: url('/fonts/Paytone_One/PaytoneOne-Regular.ttf');
    }
    @font-face {
        font-family: 'dm-sans-regular';
        src: url('/fonts/DM_Sans/DMSans-Regular.ttf');
    }
    @font-face {
        font-family: 'dm-sans-medium';
        src: url('/fonts/DM_Sans/DMSans-Medium.ttf');
    }
    @font-face {
        font-family: 'dm-sans-bold';
        src: url('/fonts/DM_Sans/DMSans-Bold.ttf');
    }

    /* Frontend Navbar */
    nav{
        position: fixed;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        flex-flow: wrap;
        background: var(--theme-color-primary);
        z-index: 12;
    }
    nav.with-info{
        height: 120px;
        margin-top: -50px;
    }

    nav .nav-info{
        width: 100%;
        background: #323b99;
        padding: 7px 30px 7px 20px;
        display: none;
    }
    nav .nav-info .col-item{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    nav .nav-info p{
        margin: 0;
        font-family: 'lucien';
        color: #ffffff;
        font-size: 14px;
        padding-right: 15px;
        line-height: 20px;
    }
    nav .nav-info p span{
        color: #deda2c;
    }
    nav .nav-info img{
        width: 70px;
    }
    nav .nav-info .btn-close-nav-review-google{
        margin-left: 15px;
        color: #ffffff;
    }

    nav .col-inner{ padding: 0px 15px; }
    nav .inner{
        display: flex;
        height: 70px;
    }
    nav .identity-col{
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    nav .identity-col a{
        font-family: paytone-one;
        font-size: 24px;
        text-decoration: none;
        color: #ffffff;
    }
    nav .identity-col a img{
        width: 150px;
        margin-top: -10px;
    }
    nav .identity-col a img.is-mobile{
        display: none;
        width: 40px;
        margin-top: 0px;
    }
    nav .menu-col.menu-col-desk{ display: flex; }
    nav .menu-col.menu-col-mob{ display: none; }
    nav .menu-col{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
        position: relative;
    }
    nav .menu-col.menu-col-desk ul{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 15px;
    }
    nav .menu-col ul{
        margin: 0;
        padding: 0;
    }
    nav .menu-col li{
        display: inline-block;
        position: relative;
    }
    nav .menu-col li a{
        padding: 0px 15px;
        text-decoration: none;
        font-family: 'poppins-light';
        color: #ffffff;
        font-size: 14px;
        height: 100%;
        display: flex;
        align-items: center;
        transition: all .2s;
        position: relative;
    }
    nav .menu-col li a i{
        margin-left: 5px;
        color: var(--theme-color-third);
        transform: rotate(0deg);
        transition: all .2s;
    }
    nav .menu-col li a i.normal-icon-right{
        margin-right: 5px;
        color: var(--theme-color-third);
        transition: all .2s;
    }
    nav .menu-col li ul.child{
        position: absolute;
        padding: 15px;
        background: var(--theme-color);
        min-width: 100%;
        width: max-content;
        display: none;
        transition: all .2s;
    }
    nav .menu-col li ul.child li{
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    nav .menu-col li ul.child li a{
        padding: 0;
        color: #ffffff;
    }
    nav .menu-col li ul.child li a:hover{ color: #ffffff; }

    nav .menu-col li:hover a{
        color: #ffffff;
    }
    nav .menu-col li:hover a i{
        transform: rotate(181deg);
        color: #ffffff;
    }
    nav .menu-col li:hover a i.normal-icon-right{
        transform: none;
    }
    nav .menu-col li:hover ul.child{
        display: block;
        transition: all .2s;
    }
    nav .menu-col li.btn-nav{
        padding: 18px 0px;
        margin-left: 10px;
    }
    nav .menu-col li.btn-nav a{
        margin-top: -5px;
        padding: 20px 20px;
        border-radius: 5px;
        border: 2px solid var(--theme-color-accent);
        font-family: 'poppins-light';
        font-size: 16px;
        color: var(--theme-color-accent);
    }
    nav .menu-col li.btn-nav a:hover{
        background: var(--theme-color-accent);
        color: #ffffff;
    }

    nav .menu-col-mob .btn-create-box{
        display: flex;
        align-items: center;
        margin-right: 45px;
    }
    nav .menu-col-mob .btn-create-box a{
        height: auto;
        padding: 5px 15px;
        border-radius: 3px;
        font-family: 'poppins-light';
        border: 2px solid var(--theme-color-accent);
        color: var(--theme-color-accent);
        text-decoration: none;
        font-size: 12px;
    }
    nav .menu-col-mob .btn-create-box a:hover{
        background: var(--theme-color-accent);
        color: #ffffff;
    }
    nav .menu-col-mob .hamburger{
        position: absolute;
        right: 0;
        top: 23px;
        cursor: pointer;
        z-index: 10;
        padding: 10px 15px 5px;
    }
    nav .menu-col-mob .hamburger .bars {
        display: block;
        position: relative;
        width: 28px;
        height: 2px;
        background-color: var(--theme-color-accent);
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    nav .menu-col-mob .hamburger .bars:before {
        top: 9px;
    }
    nav .menu-col-mob .hamburger .bars:after {
        bottom: 9px;
    }
    nav .menu-col-mob .hamburger .bars:before,
    nav .menu-col-mob .hamburger .bars:after {
        position: absolute;
        content: " ";
        width: 100%;
        height: 2px;
        background-color: var(--theme-color-accent);
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    nav .menu-col-mob.open .hamburger .bars {
        background-color: transparent;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    nav .menu-col-mob.open .hamburger .bars::before {
        top: 0;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        background-color: var(--theme-color-accent);
    }
    nav .menu-col-mob.open .hamburger .bars::after {
        bottom: 0;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background-color: var(--theme-color-accent);
    }
    nav .menu-col-mob .menu {
        width: 90%;
        height: 100%;
        padding: 60px 20px 0;
        background-color: var(--theme-color-primary);
        box-shadow: 0 0 5px rgb(0 0 0 / 70%);
        position: fixed;
        right: -100%;
        top: 0;
        opacity: 0;
        z-index: 9;
        margin-top: 0;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    nav .menu-col-mob.open .menu {
        right: 0;
        opacity: 1;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    nav .menu-col-mob .menu .inner{
        width: 100%;
        height: 100%;
        overflow-x: auto;
        display: block;
        padding-bottom: 40px;
    }
    nav .menu-col-mob .menu li{
        list-style: none;
        display: block;
    }
    nav .menu-col-mob .menu li.list-w-collapse{ margin-bottom: 30px; }
    nav .menu-col-mob .menu li .btn-collapse-a{
        text-decoration: none;
        display: block;
        padding-left: 0;
        padding-right: 35px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #2196f7;
        border-bottom: 1px solid #323b99;
        font-size: 14px;
        position: relative;
    }
    nav .menu-col-mob .menu li .btn-collapse-a.collapsed::after{
        transform: rotate(0deg);
    }
    nav .menu-col-mob .menu li .btn-collapse-a::after{
        content: "\f107";
        font: normal normal normal 28px/1 FontAwesome;
        position: absolute;
        top: 9px;
        right: 10px;
        color: #ffffff;
        transform: rotate(181deg);
        transition: all .3s;
    }
    nav .menu-col-mob .menu li .card-body{
        background-color: #131a55;
        padding: 15px 0px;
        border-bottom: 1px solid #323b99;
    }
    nav .menu-col-mob .menu li .card-body ul{
        padding: 0;
        margin-bottom: 0;
    }
    nav .menu-col-mob .menu li .card-body ul li{ margin-bottom: 15px; }
    nav .menu-col-mob .menu li .card-body ul li:last-child{ margin-bottom: 0; }
    nav .menu-col-mob .menu li .card-body ul li a{
        padding-left: 0;
        color: #ffffff;
        font-size: 14px;
    }
    nav .menu-col-mob .menu li .card-body ul li.btn-child-nav a{
        background: #ff4e00;
        height: auto;
        padding: 10px 15px;
        border-radius: 3px;
        font-family: 'montserrat-bolditalic';
        color: #ffffff;
        text-align: center;
        display: block;
    }
    nav .menu-col-mob .menu li .card-body ul li.btn-child-nav button{
        background: #ff4e00;
        height: auto;
        padding: 10px 15px;
        border-radius: 3px;
        font-family: 'montserrat-bolditalic';
        color: #ffffff;
        border: none;
        width: 100%;
        font-size: 14px;
    }
    nav .menu-col-mob .menu .auth-box{
        position: absolute;
        top: 15px;
        left: 20px;
        right: 50px;
        display: flex;
    }
    nav .menu-col-mob .menu li.list-std-acc{ margin-bottom: 0px; }
    nav .menu-col-mob .menu li.list-std{ margin-bottom: 10px; }
    nav .menu-col-mob .menu li.list-std-acc a{
        font-family: 'montserrat-medium';
        color: #2196f7;
        font-size: 14px;
        padding: 0;
    }
    nav .menu-col-mob .menu li.list-std-acc:first-child{ margin-right: 20px; }
    nav .menu-col-mob .menu li:hover a i{ transform: rotate(0deg); }
    nav .menu-col-mob .menu li.list-std-acc.active a{
        font-family: 'montserrat-bolditalic';
        background: #ff4e00;
        color: #ffffff;
        padding: 5px 15px;
        border-radius: 3px;
    }
    nav .menu-col-mob .menu li.list-std-acc.active a i{
        padding-right: 5px;
        color: #ffffff;
        margin-left: 0px;
    }
    nav .menu-col-mob .menu li.list-std a{
        color: #ffffff;
        font-size: 14px;
        padding: 0;
    }
    nav .menu-col-mob .menu li.list-std-acc.list-w-collapse {
        margin-bottom: 0px;
    }
    nav .menu-col-mob .menu li.list-std-acc.list-w-collapse a{
        color: #ffffff;
        border-bottom: none;
        position: relative;
        padding-right: 40px;
        display: flex;
        align-items: center;
    }
    nav .menu-col-mob .menu li.list-std-acc-collapse .card-body{ border-top: 1px solid #323b99; }
    nav .menu-col-mob .menu li.list-std-acc .btn-collapse-a::after{ top: 1px; }

    nav .menu-col-mob .menu .social-box{
        display: flex;
        margin-top: 30px;
    }
    nav .menu-col-mob .menu .social-box li{
        margin-right: 30px;
    }
    nav .menu-col-mob .menu .social-box li a{
        padding: 0;
        font-size: 22px;
    }
    nav .menu-col-mob .menu .social-box li a i{ color: #77bef8; }
    nav .menu-col-mob .menu .social-box li a svg{
        width: 20px;
        color: #77bef8;
    }
    nav .menu-col-mob .menu .socket{ margin-top: 10px; }
    nav .menu-col-mob .menu .socket .copyright{
        color: #ffffff;
        font-size: 14px;
        padding: 0;
        font-family: 'lucien';
    }

    /* Footer */
    footer{
        background: #000000;
        padding: 20px 0px;
    }
    footer .footer-menu{
        border-bottom: 1px solid #383838;
        margin-bottom: 20px;
        padding: 0px 0px 20px;
    }
    footer .footer-menu ul{
        margin: 0;
        padding: 0;
    }
    footer .footer-menu ul li{
        display: inline-block;
        list-style: none;        
    }
    footer .footer-menu ul li a{
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        font-family: dm-sans-regular;
    }
    footer .cp{
        font-size: 14px;
        font-family: dm-sans-regular;
        color: #ffffff;
    }

    /* Forms Element */
    input.std-inpt{
        width: 100%;
        height: 40px;
        background: #f6f6f6;
        border: none;
        text-align: center;
        padding: 5px 15px;
        outline: none;
        font-family: dm-sans-medium;
        font-size: 18px;
        letter-spacing: 3px;
    }
    input.std-inpt:read-only{
        background: #cbcbcb;
    }
    input.std-inpt.base{ background: #ffffff; }

    /* Responsive */
    @media screen and (max-width: 767px)
    {
        /* Navbar */
        nav .identity-col a{ font-size: 20px; }
        /* Header */
        header.home-header .title{ font-size: 24px; }
        header.home-header p{
            font-family: dm-sans-regular;
            font-size: 16px;
        }
    }