/* Other */
    /* Section List of Grid Type */
    section.oth-tools-sec-grid{
        padding: 70px 0px 70px;
    }
    section.oth-tools-sec-grid a{ text-decoration: none; }
    section.oth-tools-sec-grid .title{
        font-family: paytone-one;
        font-size: 28px;
    }
    section.oth-tools-sec-grid .excerpt{
        font-family: dm-sans-regular;
        font-size: 18px;
    }
    section.oth-tools-sec-grid .col-wrap .item{
        background: var(--theme-color-accent);
        border-radius: 10px;
        padding: 20px;
    }
    section.oth-tools-sec-grid .col-wrap .item .title{
        text-align: center;
        font-family: dm-sans-bold;
        font-size: 22px;
        color: #ffffff;
        margin-bottom: 0;
    }
    section.oth-tools-sec-grid .col-wrap .item .sub-item{ background: var(--theme-color-secondary); }
    section.oth-tools-sec-grid .col-wrap .item .sub-item:hover{ background: var(--theme-color-secondary-hover); }
    section.oth-tools-sec-grid .col-wrap .item .sub-item a{
        padding: 7px 15px 5px;
        text-decoration: none;
        display: block;
    }
    section.oth-tools-sec-grid .col-wrap .item .sub-item .title{
        font-size: 18px;
        font-family: dm-sans-regular;
    }
    section.oth-tools-sec-grid .btn-box a{
        text-decoration: none;
        background: #292929;
        padding: 10px 35px 10px;
        border-radius: 10px;
        color: #ffffff;
        font-family: 'dm-sans-bold';
        font-style: italic;
        font-size: 17px;
        text-align: center;
    }

    /* Section Breadcrumb */
    section.oth-breadcrumb-sec{
        padding: 120px 0px 40px;
        background: var(--theme-color-accent);
    }
    section.oth-breadcrumb-sec ul{
        margin: 0;
        padding: 0;
    }
    section.oth-breadcrumb-sec ul li{
        list-style: none;
        display: inline-block;
        color: #ffffff;
        position: relative;
        padding-right: 20px;
    }
    section.oth-breadcrumb-sec ul li:after{
        content: '/';
        position: absolute;
        right: 5px;
        top: 0;
    }
    section.oth-breadcrumb-sec ul li:last-child:after{ content: none; }
    section.oth-breadcrumb-sec ul li a{
        color: #ffffff;
        font-weight: 800;
        text-decoration: none;
    }

    /* Section Standard Calculator Converter */
    section.oth-tools-sec-std-calc-convert{
        padding: 70px 0px 40px;
    }
    section.oth-tools-sec-std-calc-convert form{
        background: var(--theme-color-third);
        padding: 30px;
    }
    section.oth-tools-sec-std-calc-convert .title{
        font-family: paytone-one;
        font-size: 28px;
    }
    section.oth-tools-sec-std-calc-convert .excerpt{
        font-family: dm-sans-regular;
        font-size: 18px;
    }
    section.oth-tools-sec-std-calc-convert .col-wrap .item{
        background: var(--theme-color-accent);
        border-radius: 10px;
        padding: 20px;
    }
    section.oth-tools-sec-std-calc-convert .col-wrap .item .title{
        text-align: center;
        font-family: dm-sans-bold;
        font-size: 22px;
        color: #ffffff;
        margin-bottom: 0;
    }    
    section.oth-tools-sec-std-calc-convert .col-base{ text-align: center; }
    section.oth-tools-sec-std-calc-convert .col-base label{
        font-family: 'dm-sans-regular';
        font-size: 18px;
    }
    /* V2 */
    section.oth-tools-sec-std-calc-convert.std-calc-convert-2 form{ background: none; padding: 0; }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 form .outer{
            background: var(--theme-color-third);
            padding: 30px;
        }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 label{
            font-family: 'dm-sans-regular';
            font-size: 18px;
            position: relative;
        }

        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .std-check input{ display: none; }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .std-check label:before {
            content:'';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid var(--theme-color-primary);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;
        }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .std-check input:checked + label:before{ background: var(--theme-color-primary); }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .std-check input:checked + label:after {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 9px;
            width: 6px;
            height: 14px;
            border: solid #ffffff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);            
        }        
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .btn-copy,
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .btn-generate{ 
            width: 100%;
            color: #ffffff;
            font-family: 'dm-sans-medium';
            border-radius: 0px 0px 7px 7px;
            padding: 10px 15px;
            font-size: 18px;
            border: none;
        }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .btn-copy{ background: var(--theme-color-primary); }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .btn-generate{ background: var(--theme-color-accent); }

        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .btn-copy svg,
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .btn-generate svg{
            width: 25px;
            margin-left: 5px;
        }

    /* Section Tool Detail More Excerpt */
    section.oth-tools-sec-more-excerpt{
        padding: 0px 0px 40px;
    }
    section.oth-tools-sec-more-excerpt .title{
        font-family: paytone-one;
        font-size: 28px;
    }
    section.oth-tools-sec-more-excerpt .excerpt{
        font-family: dm-sans-regular;
        font-size: 18px;
    }

    /* Section Article */
    section.oth-article-sec{
        padding: 7px 0px 70px;
    }
    section.oth-article-sec .title{
        font-family: paytone-one;
        font-size: 28px;
    }
    section.oth-article-sec .excerpt{
        font-family: dm-sans-regular;
        font-size: 18px;
    }

    /* Responsive */
    @media screen and (max-width: 767px)
    {
        /* Section List of Grid Type */
        section.oth-tools-sec-grid .title{ font-size: 24px; }
        section.oth-tools-sec-grid .excerpt{
            font-family: dm-sans-regular;
            font-size: 16px;
        }
        section.oth-tools-sec-grid .col-wrap .item .title{ font-size: 20px; }
        section.oth-tools-sec-grid .col-wrap .item .sub-item .title{ font-size: 15px; }
        section.oth-tools-sec-grid .btn-box a{ display: block; }

        /* Section Standard Calculator Converter */
        section.oth-tools-sec-std-calc-convert .title{ font-size: 24px; }
        section.oth-tools-sec-std-calc-convert .excerpt{ font-size: 16px; }
        section.oth-tools-sec-std-calc-convert .col-wrap .item .title{ font-size: 20px; }
        /* V2 */
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 form .outer .col-mob-2{ margin-top: 30px; }
        section.oth-tools-sec-std-calc-convert.std-calc-convert-2 .btn-generate{ border-radius: 0; }
        /* Section Tool Detail More Excerpt */
        section.oth-tools-sec-more-excerpt .title{ font-size: 24px; }
        section.oth-tools-sec-more-excerpt .excerpt{ font-size: 16px; }
    }